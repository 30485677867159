import Vue from "vue";
import VueI18n from "vue-i18n";
import locale from 'element-ui/lib/locale';
// 引入 elementui 的多语言
import enLocale from 'element-ui/lib/locale/lang/en'
import zhCnLocale from 'element-ui/lib/locale/lang/zh-CN'
// 引入自己定义的 I18n 文件
import en from "./lan/en.json";
import zh from "./lan/zh.json";
Vue.use(VueI18n); // 全局挂载
export const i18n = new VueI18n({
  locale: localStorage.getItem("lang") || "en", 
  messages: {
    zh: Object.assign(zh, zhCnLocale), // 中文语言包
    en: Object.assign(en, enLocale) // 英文语言包
  }
});
locale.i18n((key, value) => i18n.t(key, value));
export default i18n;