// src/plugins/getLocaleProperty.js
export default {
    install(Vue, options = {}) {
      // 定义全局方法
      Vue.prototype.$getLocaleProperty = function (item, property, config = {}) {
        const lang =  localStorage.getItem("lang") ||
        this.$store.state.currentLanguage ||
        "en"; // 默认语言为 'zh'
        const prefix = config.prefix || 'english_'; // 默认前缀为 'english_'
        const defaultValue = config.defaultValue || ''; // 默认返回空字符串
  
        const localizedProperty = `${prefix}${property}`;
  
        if (lang === 'en') {
          return item[localizedProperty] !== undefined ? item[localizedProperty] : defaultValue;
        } else {
          return item[property] !== undefined ? item[property] : defaultValue;
        }
      };
    }
    // 使用方法
     // 获取 skin_name，使用默认前缀和默认值
    //  const skinName = this.$getLocaleProperty(this.item, 'skin_name');
    //  console.log(skinName);
 
    //  // 获取 item_name，使用自定义前缀 'fr_' 和默认值 'N/A'
    //  const itemName = this.$getLocaleProperty(this.item, 'item_name', { prefix: 'fr_', defaultValue: 'N/A' });
    //  console.log(itemName);
  };