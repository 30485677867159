<template>
  <div class="container">
    <div class="cont" v-if="lang=='zh'">
      <div class="title-top"><span class="el-icon-arrow-left" @click="$router.back()" style="cursor:pointer"></span> 帮助中心</div>
      <div class="collapse">
        <el-collapse v-model="activeNames">
          <el-collapse-item title="关于我们" name="1">
            <div class="doubt1">
              <div>
              "于2019年12月成3立，致力于开发电子竞技构建数字内容生态，为用户提供更多了安全的娱乐渠道，使用独特的交易模式，促使steam娱乐更快捷安全的提取到用户得库存，被众多玩家所喜爱。"</span><br>
				<span>"csgobox.xyz是国内货涵盖全面的CSGO饰品交易品牌。csgobox.xyz网站上，你可以即时获得更多的csgo的物品，csgobox.xyz特惠商城有海量的商品可随时取回到库存，其库存量没有任何一家同类型网站可以比拟。"<br></span>
				<span>"联系方式"：2748409013@qq.com</span><br>
            </div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="用户协议" name="2">
            <div class="doubt2">
              <div class="doubt-warp">
			<div class="title">'用户协议'</div>
			<div class="title1">
				"您同意在我们的网站上注册账户或以其他方式使用我们的服务，即表示您同意以下条款和条件(“条款”)。如果您不同意这些条款，请勿注册账户或以其他方式使用我们的服务。"
			</div>
			<div class="title1">
				"请注意，您对服务的使用也受我们隐私政策的约束。本公司保留随时更新条款和隐私政策的权利，恕不另行通知。"
			</div>

			<div class="con">
				<h3> 1."定义"</h3> <br>
				"账户: 用户在平台上为访问服务而创建的一个或多个账户。" <br>
				"平台: 公司和/或其附属机构发布的平台，允许用户与其他用户交换通证。该平台不允许用户将通证交换为法定货币。"<br>
				"服务: 通过网站、附属网站、应用程序和平台提供的任何服务。"<br>
				"网站: csgobox.xyz，网址为: https://csgobox.xyz/"<br>
				"用户: 任何服务的用户(此处对“您”或“您的”的所有引用均指每个用户)。"<br>
				<h3>2 ."资格、限制区域和允许用途"</h3><br>
				"资格。 通过访问服务，您需保证:"<br>
				( 1 )"年龄至少为18岁，或至少为适用法律规定的最低年龄，以便我们无需经过您父母同意即可合法向您提供服务；"<br>
				( 2 )"加入这些条款(并且在法律上有能力这样做)"；<br>
				( 4 )"所在国家适用法律不禁止访问和使用服务；"<br>
				( 5 ) "未有过因违法操作，被暂停交易或者调离交易现场的记录；"<br>
				( 6 )"拥有您在平台上使用的所有通证(或您能够与之进行授权交易的通证)"；<br>
				( 7 )"拥有全部必要的同意和授权，以执行从您的账户发起的全部交易；"<br>
				( 8 )"提供真实、准确、完整的开户资料；"<br>
				( 9 )"已审阅本条款所载的风险披露声明，并同意承担其中所述的所有风险。"<br>
				"允许使用。"
				"您同意在访问服务时遵守所有适用的法律和法规。您同意不将服务用于任何非法活动，包括但不限于非法赌博、洗钱、欺诈、敲诈、勒索、数据交换、资助恐怖主义或其他暴力活动。您还同意，您不会对平台、站点和应用程序进行黑客、反编译、反向工程、反汇编、尝试获取源代码、解密或以其他方式损害其完整性和安全性。"<br>
				<h3>3 ."账户"</h3><br>
				"您必须创建账户才能访问和使用服务，并且必须提供有效的电子邮件地址才能创建账户。您必须在创建账户时提供完整、准确和最新的信息，并在发生任何更改时立即更新这些信息。您可以请求公司删除或去除您提供的任何信息；但是，您承认并同意公司可以拒绝您的请求或恢复以前删除和去除的任何信息。您必须对您账户下发生的所有活动负责，并承担未经授权访问的所有风险。您同意维护登录凭证的安全性和机密性，并拒绝在未经授权的情况下(无论是通过站点、应用程序还是其他方式)访问您的账户。如果您发现或怀疑任何未经授权访问或使用您的账户，必须立即通知公司。"<br>
				"您同意公司：有权限制任何用户或关联公司能够创建的账户数量。"<br>
				"您同意本公司保留：随时以任何理由终止您的账户的权利和唯一决定权。"<br>
				<h3>4 ."风险披露表"</h3><br>
				(A) "技术"<br>
				"复杂性。"
				"通证一般用非常技术性的语言描述；需要对应用密码学和计算机科学有全面的了解，才能认识到固有的风险。通过使用本服务，您表示并保证您拥有足够的知识、市场成熟度、经验和/或专业建议，足以对您根据本服务所进行的所有交易的价值和风险进行审慎评估。您同意承担上述评估的全部责任。"<br>
				"网络攻击。 如果平台、应用程序、站点或服务的任何其他组件遭到网络攻击，您的账户和其中的通证可能会受到不利影响。本公司及其附属公司均不保证其在发生此类攻击时可预见、预防、减轻或采取纠正措施。"<br>
				"暂停/停止支助/撤出。 如果在平台上交易的特定通证发生分叉或网络攻击，您同意公司可以暂时中止相关通证的服务(无论是否事先通知您)，并且公司可以不支持(或停止支持)"
				"分叉通证。如果可行，公司将为您提供收回此类通证的机会。有关受攻击通证的存款、提款和用户余额的决议将由公司根据具体情况自行决定。本公司不就平台的安全性做出任何陈述或保证，也不对任何价值损失或被盗财产负责，无论本公司是否疏忽提供适当的安全性。"<br>
				（B）"交易失败；网上存款条款"<br>
				"平台上的交易可能由于若干原因而失败，包括但不限于价格变化。本公司不对任何交易的正常执行做出任何陈述或保证。本公司特此声明：放弃负责因任何交易失败而产生、或与任何交易失败相关的所有损失和损害。您同意并承认公司并没有责任通知交易失败。"
				"公司可在任何时候拒绝执行交易、施加交易金额的限制或约束，无需事先通知，并可自行决定。具体而言，如果公司怀疑可能存在洗钱、资助恐怖主义、欺诈或任何其他非法行为，或者公司怀疑交易违反了相关服务条款[这些条款是指？](例如，涉及开设账户和随后关闭账户而不进行任何实际交易的交易)，公司保留拒绝处理、取消或撤销任何交易和禁用用户账户的权利。虽然公司可酌情撤销交易，但用户不得更改、撤回或取消其交易授权，但部分填写的订单除外。本公司可更正、冲销或取消任何因处理交易指示错误及其他原因而影响的交易。如果出现错误，您的补救措施将受到限制。我们不能保证此类取消或退款始终是可能的。公司的平台运行使得每个用户的订单与其他用户的订单相匹配。根据下订单时的交易活动，可以部分填写订单或填写多个订单。 您确认公司为每个账户提供加密通证存储功能，以便进行交易。"<br>
				（C）中止<br>
				本公司有权随时终止先前提供的通证可用性。在这种情况下，公司将为您提供收回这些通证的机会。<br>
				<h3>5 .许可限制</h3><br>
				本公司授予您有限、非排他性、不可转让的许可，允许您访问和使用本服务(无论是通过应用程序还是网站)，仅用于本服务的预期功能以及本公司明确允许的其他批准用途。明确禁止任何其他使用服务的行为。<br>
				使用应用程序时，您同意不会去:<br>
				( A )反编译、反向工程、反汇编、尝试导出应用程序的源代码或解密应用程序；<br>
				( B )对应用程序进行任何修改、改编、改进、增强、翻译或衍生作品；<br>
				( C )违反与您访问或使用应用程序相关的任何适用法律、规则或法规；<br>
				( E )将该应用程序用于并非设计或意图用于的任何其他目的；<br>
				( F )通过网络提供应用程序，或以其他方式允许多个移动设备或用户同时访问或使用该应用程序；<br>
				( G )使用应用程序向任何网站发送自动查询或发送任何未经请求的商业电子邮件；<br>
				( H )未经授权使用公司或其关联公司的专有信息或知识产权(包括但不限于商标)。<br>
				<h3>6 .用户行为守则</h3><br>
				使用或访问服务时，您同意不会:<br>
				( A )通过密码挖掘或其他方式获取或企图获取对网站、其他用户账户或服务任何其他组成部分的未经授权访问；<br>
				( B )在网站或服务的任何组成部分上使用或企图使用网络爬虫；<br>
				( C )对我们的基础设施造成巨大负担；<br>
				( D )将含有计算机病毒、特洛伊木马或蠕虫的任何材料上载到网站。<br>
				<h3>7 .隐私政策相关</h3><br>
				你的隐私对我们很重要。但是，我们可能被迫根据相应的法律、规则或法规共享您的信息。有关详细信息，请参阅我们的《隐私政策》。<br>
				<h3>8 .反洗钱和验证用户身份政策</h3><br>
				公司执行银行层面的(“验证用户身份”)流程，以遵守反洗钱( AML
				)法律。该公司对照政府观察名单对用户数据进行交叉核对。如果用户或用户的交易被标记为可疑，公司将要求用户提供额外的身份证明，并可暂停该用户的任何交易、存款或提款，直至收到令公司满意的身份证明。<br>
				公司对用户验证级别使用内部政策。本公司有权利为任何用户确定适当验证级别和标准的，以及在不事先通知的情况下降低用户级别的权利。公司可随时执行措施，根据国籍、居住国或任何其他因素限制核查水平和标准。<br>
				您同意，如果无法达到所需的验证级别，则可能无法存放或提取通证。您同意独自承担责任，不要求公司或其关联公司对此类损失承担责任。<br>
				您特此授权公司直接（或通过第三方）进行任何我们所认为必要的查询，以验证您的身份并/或防止欺诈行为的发生，包括查询公开报告中包含的身份信息(例如您的姓名、地址、过去地址或出生日期)，查询与您关联的银行账户相关的账户信息(例如姓名或账户余额)，并根据此类查询和报告的结果采取我们认为合理且必要的行动。您还授权可能收到此类查询或请求的任何和所有第三方对此类查询或请求做出全面响应。<br>
				<h3>9 .不可转让性</h3><br>
				虽然向用户提供的账户和服务不可转让，但公司有权将账户和服务的所有权、利益进行义务转让、转让或出售给任何人，这些条款对公司或其贷款人的继承人和受让人(如有)继续有效。<br>
				<h3>10 .永久性撤回服务</h3><br>
				公司有权暂停或终止您对服务的访问。以及如果公司根据适用的法律、规则或法规，怀疑您或其他人使用您的账户从事非法活动，则可以停用或取消您的账户。在这种情况下，除非另有相应的法律禁止，否则您可以在账户停用或取消后
				60天内转让与您的账户相关的通证。如果您的账户在被取消或暂停时，任何交易处于挂起状态，则可根据需要取消或退还此类交易。<br>
				通证返还流程：公司注销您的账户——您授权公司取消或暂停挂起交易——公司通过服务或应用程序向您发出通知——与交易相关联的通证会返回公司为您保留的钱包地址。<br>
				如果本公司无法通过合理的商业努力将您的通证归还给CoinFit为您存档的钱包地址，本公司可根据香港无人认领财产法向适用的政府机构报告和汇寄通证。<br>
				<h3>11 .免责声明与免责事项</h3><br>
				用户了解并同意，在任何情况下，我们不就以下各事项承担责任：<br>
				(1)收入的损失；<br>
				(2)交易利润或合同损失；<br>
				(3)业务中断；<br>
				(4)预期可节省的货币的损失；<br>
				(5)信息的损失；<br>
				(6)机会、商誉或声誉的损失；<br>
				(7)数据的损坏或损失；<br>
				(8)购买替代产品或服务的成本；<br>
				(9)数字资产消亡或退出市场；<br>
				(10)任何由于侵权（包括过失）、违约或其他任何原因产生的间接的、特殊的或附带性的损失或损害，不论这种损失或损害是否可以为我们合理预见；不论我们是否事先被告知存在此种损失或损害的可能性。<br>
				（1）条至（10）条均是彼此独立的。<br>
				用户了解并同意，我们不对因下述任一情况而导致用户的任何损害赔偿承担责任：<br>
				(1)我们有合理理由认为用户的具体交易事项可能存在重大违法或违约情形。<br>
				(2) 我们有合理的理由认为用户在我们的行为涉嫌违法或不当。<br>
				(3) 通过我们服务购买或获取任何数据、信息或进行交易等行为或替代行为产生的费用及损失。<br>
				(4)用户对本网站服务的误解。<br>
				(5)非因我们的原因而造成用户信息被泄露、毁损或灭失。<br>
				(6)任何非因我们的原因而引起的与我们提供的服务有关的其它损失。<br>
				(7)我们不能保证我们包含的全部信息、程序、文本等完全安全，不受任何病毒、木马等恶意程序的干扰和破坏，故用户登陆、使用我们任何服务或下载及使用该下载的任何程序、信息、数据等均是用户个人的决定并自行承担风险及可能产生的损失。<br>
				(8)我们对我们链接的任何第三方网站的任何信息、产品及业务及其他任何形式的不属于我们的主体的内容等不做任何保证和承诺，用户如果使用第三方网站提供的任何服务、信息及产品等均为用户个人决定且承担由此产生的一切责任。<br>
				(9)我们对于用户使用我们服务不做任何明示或暗示的保证，包括但不限于我们提供服务的适用性、没有错误或疏漏、持续性、准确性、可靠性、适用于某一特定用途。同时，我们也不对我们提供的服务所涉及的技术及信息的有效性、准确性、正确性、可靠性、质量、稳定、完整和及时性作出任何承诺和保证。是否登陆或使用我们提供的服务是用户个人的决定且自行承担风险及可能产生的损失。我们对于数字资产的市场、价值及价格等不做任何明示或暗示的保证，用户理解并了解数字资产市场是不稳定的，价格和价值随时会大幅波动或崩盘，交易数字资产是用户个人的自由选择及决定且自行承担风险及可能产生的损失。<br>
				(10)协议中规定的我们的保证和承诺是由我们就本协议和我们提供的服务的唯一保证和陈述，并取代任何其他途径和方式产生的保证和承诺，无论是书面的或口头的，​​明示的或暗示的。所有这些保证和陈述仅仅代表我们自身的承诺和保证，并不保证任何第三方遵守本协议中的保证和承诺。<br>
				(11)我们并不放弃本协议中未提及的在法律适用的最大范围内我们享有的限制、免除或抵销我们损害赔偿责任的任何权利。<br>
				<h3>12 .适用法律和争议解决</h3><br>
				本使用条款应受香港法律管辖，并应在所有方面解释为香港合同。由本使用条款引起或与本使用条款相关的任何索赔或诉讼(包括侵权索赔)应受香港法律管辖，并根据香港法律进行解释和执行。<br>
				为加快并控制争议成本，您同意，您使用服务或本条款、或您使用服务或本条款相关的任何法律引起的索赔，包括本条款的形成、有效性、可执行性、范围或适用性(以下简称“索赔”)将按以下方式解决:除了禁令或衡平法上的救济索赔或知识产权索赔(可在任何主管法院提起而无需出具保函)之外，您使用服务引起的任何争议应根据香港国际仲裁中心(以下简称“仲裁中心”)通过保密、有约束力的仲裁最终解决。这意味着，除知识产权诉讼之外的所有索赔，如版权或商标侵权诉讼，或寻求非金钱救济的索赔，都将受到具有约束力的仲裁。仲裁应以英文在香港进行，仲裁决定可在任何法院执行。<br>
				要开始仲裁程序，您必须通过邮件发送一封请求仲裁的信函，并向我们描述您的索赔要求.<br>
				<h3>13 .没有集体诉讼</h3><br>
				您和公司只能以您或其个人身份对另一方提出索赔，而不能在任何声称的类别、代表、合并或多索赔人诉讼中作为原告或类别成员提出索赔。<br>
				你和公司都放弃了陪审团审判的权利。<br>
				<h3>14 .可分性；弃权</h3><br>
				如果本条款中的任何条款被视为非法、无效或由于任何原因无法执行，则该条款应被视为可与本条款分离，且不会影响任何剩余条款的有效性和执行性。本公司延迟或未能执行其任何权利不应构成对该等权利的持续放弃。<br>
				<h3>15 .整合</h3><br>
				本条款以及公司发布的任何政策或运营规则构成您与公司之间的完整协议和了解，并取代您与公司之间以前或同时期的任何形式的协议、沟通和建议(包括但不限于本条款的任何以前版本)。<br>
				<h3>16 .不可抗力因素</h3><br>
				除上述适用免责声明外，如果由于超出公司合理控制范围的原因(包括但不限于天灾、任何政府行为、战争或其他敌对行为、内乱、火灾、水灾、暴风雪、地震、爆炸、禁运、恐怖主义行为、停电、设备故障、劳资纠纷或争议、任何第三方数据提供商或其他第三方信息提供商的行为、第三方软件或通信方法中断)而导致中断和/或延迟，公司应免除履行本条款。<br>
				<h3>17 .修正</h3><br>
				公司可随时修改、删除或以其他方式修改本使用条款和隐私政策中的任何条款；前提是，公司至少在修改前七天，通过您在公司存档的电子邮件向您发出通知。<br>
				<h3>18 .暂停和终止服务</h3><br>
				公司可出于任何原因，在事先通知或不事先通知您的情况下，暂停和终止服务。您承认并同意，如果市场状况不稳定、不合理，公司可在事先通知或不通知您的情况下，通过启动交易限制或其他适当的措施，终止服务。<br>
			</div>
		</div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="隐私条款" name="3">
            <div class="doubt3">
              <div class="doubt-warp">
      <div class="title">隐私条款</div>
      <div class="con">本《隐私政策》适用于我们提供的一切csgobox.xyz服务。当您使用我们任何单项服务时，您同意接受本《隐私政策》以及我们在该单项服务中发出的特定隐私信息类政策条款（下列称“特定条款”）的保护，在此情况下特定条款与本政策条款同时对您产生效力。<br>
        请注意我们不时地会检查我们的政策，因此有关的措施会随之变化。我们恳请您定期光顾本页面，以确保对我们《隐私政策》最新版本始终保持了解。在阅读完本政策之后，如您对本《隐私政策》或与本《隐私政策》相关的事宜有任何问题，请与csgobox.xyz客服联系。<br>
        您使用或继续使用csgobox.xyz服务，都表示您同意我们按照本《隐私政策》收集、使用、储存和分享您的信息。<br>
        <h3>一、我们可能收集的信息</h3><br>
        （一）与个人身份无关的信息：<br>
        当您使用csgobox.xyz服务时，我们可能收集和汇总诸如用户的来源途径、访问顺序等信息，例如记录使用csgobox.xyz服务的每个用户的来源途径、浏览器软件等。<br>
        （二）有关个人身份的信息：<br>
        当您使用csgobox.xyz服务时，我们可能收集和汇总或要求您提供有关个人身份的信息，例如个人：生日、籍贯、性别、兴趣爱好、个人电话号码、（包括系统账号、IP地址、电子邮箱地址等）；个人财产信息（交易记录、余额、优惠券、兑换码等）；通讯信息；个人上网记录和日志信息（包括网站浏览记录、软件使用记录、点击记录、操作日志等）；设备信息（包括设备型号、设备MAC地址、操作系统类型、设备设置）；软件列表唯一设备识别码（如IMEI/android ID/IDFA/OPENUDID/GUID、SIM卡IMSI信息等在内的描述个人常用设备基本情况的信息）；位置信息（包括行程信息、精准定位信息、住宿信息、经纬度等）。<br>
        我们收集您的信息主要是为了您和其他用户能够更容易和更满意地使用csgobox.xyz服务。csgobox.xyz的目标是向所有的互联网用户提供安全、刺激、有趣及有教益的上网经历。而这些信息有助于我们实现这一目标。<br>
        <h3>二、我们如何收集和使用信息</h3><br>
        （一）我们将通过以下途径收集和获得您的信息：<br>
        1、您提供的信息。 例如：<br>
        （1）您在注册csgobox.xyz服务的帐号或使用csgobox.xyz服务时，向我们提供的信息；<br>
        （2）您通过csgobox.xyz服务向其他方提供的共享信息，以及您使用csgobox.xyz服务时所储存的信息。<br>
        2、我们获取的您的信息。您在使用csgobox.xyz服务时，我们收集、汇总、记录的信息，例如日志信息、位置信息、设备信息等。<br>
        （二）COOKIES、日志档案和WEB BEACON<br>
        我们或我们的第三方合作伙伴可能通过COOKIES和WEB BEACON获取和使用您的信息，并将该等信息储存为日志信息。通过使用COOKIES，我们向用户提供简单易行并富个性化的网络体验。一个COOKIES是少量的数据，它们从一个网络服务器送至您的浏览器并存在计算机硬盘上。我们使用COOKIES是为了让其用户可以受益。比如，为使得csgobox.xyz虚拟社区的登录过程更快捷，您可以选择把用户名存在一个COOKIES中。这样下次当您要登录csgobox.xyz的服务时能更加方便快捷。COOKIES能帮助我们确定您连接的页面和内容，您在csgobox.xyz特定服务上花费的时间和您所选择的csgobox.xyz服务。COOKIES使得我们能更好、更快地为您服务，并且使您在csgobox.xyz服务上的经历更富个性化。然而，您应该能够控制COOKIES是否以及怎样被你的浏览器接受。请查阅您的浏览器附带的文件以获得更多这方面的信息。<br>
        我们使用自己的COOKIES和WEB BEACON，用于以下用途：<br>
        （1）记住您的身份。例如：COOKIES和WEB BEACON有助于我们辨认您作为我们的注册用户的身份，或保存您向我们提供有关您的喜好或其他信息；<br>
        （2）分析您使用我们服务的情况。我们可利用COOKIES和WEB BEACON来了解您使用csgobox.xyz服务进行什么活动、或哪些服务或服务最受欢迎；及<br>
        （3）广告优化。COOKIES和WEB BEACON有助于我们根据您的信息，向您提供与您相关的广告而非进行普遍的广告投放。<br>
        我们为上述目的使用COOKIES和WEB BEACON的同时，可能将通过COOKIES和WEB BEACON收集的非个人身份信息汇总提供给广告商和其他伙伴，用于分析您和其他用户如何使用csgobox.xyz服务并用于广告服务。<br>
        csgobox.xyz服务上可能会有广告商和其他合作方放置的COOKIES和WEB BEACON。这些COOKIES和WEB BEACON可能会收集与您相关的非个人身份信息，以用于分析用户如何使用该等服务、向您发送您可能感兴趣的广告，或用于评估广告服务的效果。这些第三方COOKIES和WEB BEACON收集和使用该等信息不受本《隐私政策》约束，而是受到其自身的个人信息保护声明约束，我们不对第三方的COOKIES或WEB BEACON承担责任。<br>
        您可以通过浏览器或用户选择机制拒绝或管理COOKIES或WEB BEACON。但请您注意，如果您停用COOKIES或WEB BEACON，我们有可能无法为您提供最佳的服务体验，某些服务也可能无法正常使用。同时，您仍然将收到广告，只是这些广告与您的相关性会降低。<br>
        （三）我们会出于以下目的，收集和使用您的信息：<br>
        1、帮助您完成注册<br>
        为便于我们为您提供服务，您需要提供基本注册信息，例如手机号码、电子邮箱地址等，并创建您的用户名和密码。在部分单项服务中，如果您仅需使用浏览、搜索等基本服务，您不需要注册成为csgobox.xyz用户及提供上述信息。<br>
        2、向您提供商品或服务<br>
        我们所收集和使用的信息是为您提供csgobox.xyz服务的必要条件，如缺少相关信息，我们将无法为您提供csgobox.xyz服务的核心内容，例如：<br>
        （1）在部分服务项目中，为便于向您交付商品或服务，您需提供收货人个人身份信息、姓名、收货地址、邮政编码、收货人、联系电话、支付状态等信息。如果您拒绝提供此类信息，我们将无法完成相关交付服务。如您通过csgobox.xyz服务为其他人订购商品或服务，您需要提供该实际订购人的前述信息。向我们提供该实际订购人的前述信息之前，您需确保您已经取得其授权同意。<br>
        （2）为展示您账户的订单信息，我们会收集您在使用csgobox.xyz服务过程中产生的订单信息用于向您展示及便于您对订单进行管理；<br>
        （3）当您与我们联系时，我们可能会保存您的通信/通话记录和内容或您留下的联系方式等信息，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。<br>
        （4）为确认交易状态及为您提供售后与争议解决服务，我们会通过您基于交易所选择的交易对象、支付机构、物流公司等收集与交易进度相关的您的交易、支付、物流信息，或将您的交易信息共享给上述服务提供者。<br>
        3、为您提供安全保障<br>
        为确保您身份真实性、向您提供更好的安全保障，您可以向我们提供身份证明、面部特征等生物识别信息等个人敏感信息以完成实名认证。<br>
        除身份验证外，我们可能将您的信息用于客户服务、安全防范、诈骗监测、存档和备份等用途，确保我们向您提供的服务的安全性；我们可能使用或整合我们所收集的您的信息，以及我们的合作伙伴取得您授权或依据法律共享的信息，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。<br>
        <h3>三、我们可能分享、转让或披露的信息</h3><br>
        （一）分享<br>
        除以下情形外，未经您同意，我们不会与csgobox.xyz之外的任何第三方分享您的信息：<br>
        1、向您提供我们的服务。我们可能向合作伙伴及其他第三方分享您的信息，以实现您需要的核心功能或提供您需要的服务，例如：向物流服务商提供对应的订单信息；<br>
        2、维护和改善我们的服务。我们可能向合作伙伴及其他第三方分享您的信息，以帮助我们为您提供更有针对性、更完善的服务，例如：代表我们发出电子邮件或推送通知的通讯服务提供商等；<br>
        3、实现本《隐私政策》第二条“我们如何收集和使用信息”部分所述目的；<br>
        4、履行我们在本《隐私政策》或我们与您达成的其他协议中的义务和行使我们的权利；<br>
        5、向委托我们进行推广的合作伙伴等第三方共享，但我们仅会向这些委托方提供推广的覆盖面和有效性的信息，而不会提供可以识别您身份的信息，例如姓名电话号码或电子邮箱；或者我们将这些信息进行汇总，以便它不会识别您个人。比如我们可以告知该委托方有多少人看了他们的推广信息或在看到这些信息后购买了委托方的商品，或者向他们提供不能识别个人身份的统计信息，帮助他们了解其受众或顾客。<br>
        6、在法律法规允许的范围内，为了遵守法律、维护我们及我们的关联方或合作伙伴、您或其他csgobox.xyz用户或社会公众利益、财产或安全免遭损害，比如为防止欺诈等违法活动和减少信用风险，我们可能与其他公司和组织交换信息。不过,这并不包括违反本《隐私政策》中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的信息。<br>
        （二）转让<br>
        1、随着我们业务的持续发展，我们有可能进行合并、收购、资产转让或类似的交易，而您的信息有可能作为此类交易的一部分而被转移。我们会要求新的持有您信息的公司、组织继续受本《隐私政策》的约束，否则,我们将要求该公司、组织重新向您征求授权同意。<br>
        2、在获得您的明确同意后，我们会向其他方转让您的信息。<br>
        （三）披露<br>
        我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会披露您的信息：
        1、根据您的需求，在您明确同意的披露方式下披露您所指定的信息；
        2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您信息的情况下，我们可能会依据所要求的信息类型和披露方式披露您的信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求接收方必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。<br>
        <h3>四、我们如何保留、储存和保护信息</h3><br>
        我们将采取以下手段保护您的信息：<br>
        （一）数据安全技术措施<br>
        我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的信息遭到未经授权的访问使用、修改,避免数据的损坏或丢失。网络服务采取了多种加密技术，例如在某些服务中，我们将利用加密技术（例如SSL）来保护您的信息，采取加密技术对您的信息进行加密保存，并通过隔离技术进行隔离。 在信息使用时，例如信息展示、信息关联计算，我们会采用多种数据脱敏技术增强信息在使用中安全性。采用严格的数据访问权限控制和多重身份认证技术保护信息，避免数据被违规使用。<br>
        （二）我们为保护信息采取的其他安全措施<br>
        我们通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范信息的存储和使用。<br>
        我们通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。<br>
        加强安全意识。我们还会举办安全和隐私保护培训课程，加强员工对于保护信息重要性的认识。<br>
        （三）我们仅允许有必要知晓这些信息的csgobox.xyz员工、合作伙伴访问您的信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您的信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与csgobox.xyz的合作关系。<br>
        （四）我们会采取一切合理可行的措施，确保未收集无关的信息。<br>
        （五）互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件或其他服务软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的信息安全。<br>
        （六）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。<br>
        （七）安全事件处置<br>
        在通过csgobox.xyz服务与第三方进行沟通或购买商品及服务时，您不可避免的要向交易对方或潜在的交易对方披露自己的信息，如联络方式或者邮政地址等。请您妥善保护自己的信息，仅在必要的情形下向他人提供。<br>
        为应对信息泄露、损毁和丢失等可能出现的风险，我们制定了多项制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。我们也为安全事件建立了专门的应急响应团队，按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。<br>
        在不幸发生信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报信息安全事件的处置情况。<br>
        请您理解，由于技术的限制以及风险防范的局限，即便我们已经尽量加强安全措施，也无法始终保证信息百分之百的安全。您需要了解，您接入csgobox.xyz服务所用的系统和通讯网络，有可能因我们可控范围外的情况而发生问题。<br>
        <h3>五、如何联系我们</h3><br>
        如您有关于网络信息安全的投诉和举报，或您对本《隐私政策》、您的信息的相关事宜有任何问题、意见或建议，以及有关本声明或csgobox.xyz的隐私措施的问题请与csgobox.xyz的协调人联系。
      </div><br>
    </div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="协议与条款" name="4">
            <div class="doubt4">
              <div class="doubt-warp">
      <div class="title">条款协议</div>
      <div class="title1">一、退款条款与免责条款</div>
      <div class="con"><span>1、用户理解并确认，在使用本条款项下的服务的过程中，可能会遇到不可抗力等风险因素，使得充值不到账情况。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成影响的客观事件，包括服务器异常、紧急突发bug、支付渠道延迟到账等问题。出现上述情况时，csgobox.xyz 将努力在第一时间配合，及时进行修复，并保证充值到账。但此项并不可以作为退款理由。</span><br>

        <span>2、本条款项下的服务同大多数互联网服务一样，受包括但不限于用户原因、网络服务质量、社会环境等因素的差异影响，可能受到各种安全问题的侵扰，如他人利用用户的资料，造成现实生活中的骚扰；用户下载安装的其它软件或访问的其他网站中含有“特洛伊木马”等病毒，威胁到用户的计算机信息和数据的安全，继而影响本条款项下的服务的正常使用等等。用户应加强信息安全及使用者资料的保护意识，要注意加强密码保护，以免遭致损失和骚扰。</span><br>

        <span>3、用户理解并确认，本条款项下的服务存在因不可抗力、计算机病毒或黑客攻击、系统不稳定、用户所在位置、用户关机以及其他任何技术、互联网络、通信线路原因等造成的服务中断或不能满足用户要求的风险，因此导致的用户或第三方任何损失，csgobox.xyz 及时进行修复，但在法律允许的范围内免责，并且拒绝不合理投诉。</span><br>

        <span>4、用户理解并确认，在使用本条款项下的服务过程中存在来自任何他人的包括误导性的、欺骗性的、威胁性的、诽谤性的、令人反感的或非法的信息，或侵犯他人权利的匿名或冒名的信息，以及伴随该等信息的行为，因此导致的用户或第三方的任何损失，csgobox.xyz 不承担任何责任。</span><br>

        <span>5、用户理解并确认，csgobox.xyz 需要定期或不定期地对 csgobox.xyz 或相关的设备进行检修或者维护，如因此类情况而造成服务在合理时间内的中断，平台无需为此承担任何责任，但csgobox.xyz 应事先进行通告。</span>
      </div>

      <div class="title1" style="margin-top: 42px;">二、取回条款</div>
      <div class="con">

        <span>1、当用户取回的饰品缺货时，可以向客服申请补货，如供货商暂时缺货，请耐心等待。</span>

        <span>2、如果出现了取回失败、取回延迟到账问题，csgobox.xyz 有义务第一时间为用户解决问题，保障用户权益。但这并不是用户退款或恶意投诉的理由。</span>

        <span>3、用户如果出现了误提，或账号被盗导致了提取，平台尽力帮助用户解决问题。但这并不是用户退款或恶意投诉的理由。</span>
      </div>
    </div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="常见问题" name="5">
            <div class="doubt-warp5">
			<ul>
				<li>
					<div class="name">联系我们</div>
					<p class="p">联系我们，我们会第一时间处理您的反馈</p>
					<p class="p">客服邮箱：{{email}}</p>
					<p class="p">QQ：{{qq}}</p>
				</li>
				<li>
					<div class="name">常规问题</div>

					<div class="name-title">1.无法通过 Steam 注册/登录</div>
					<p class="title-p">由于国内限制，用户在使用 Steam 注册/登录时可能出现无法连接、拒绝访问等问题，用户可通过安装网游加速器加速 Steam
						社区来解决此问题。如仍存在问题，可尝试通过更换浏览器解决。</p>

					<div class="name-title">2.Steam 交易链接获取</div>
					<div class="title1">2.1 PC端用户</div>
					<div class="title2">(1).<span @click="goUrl(url1)" style="cursor:pointer;text-decoration: underline;
					color: #17b4ed;">获取 Steam 交易地址</span></div>
					<div class="title2">(2).找到第三方网站获取 Steam 交易链接</div>
					<div class="img"><img src="../assets/img/faq/faq1.png"> </div>
					<div class="title1">2.2 手机端用户</div>
					<div class="title2">(1).下载 Steam App，登录后，通过流程“您与好友—库存—交易报价—右侧菜单按钮—谁能向我发送交易报价”，在最下面即可找到交易链接。</div>
					<div class="img"><img src="../assets/img/faq/faq2.png"> </div>

					<div class="name-title">3.充值相关问题</div>
					<div class="title3">Q：充值手续费是什么？？</div>
					<div class="title4">A：平台会收取饰品充值总额15%的手续费（仍高于市场价）。手续费用于平衡由饰品 7
						天交易限制带来的饰品价格波动、账号锁定风险以及饰品售卖时向第三方平台支付手续费等成本支出。</div>
					<div class="title3">Q：饰品充值为什么会显示非可用饰品？</div>
					<div class="title4">A：存在交易冷却或交易限制的饰品是不可以进行充值的。</div>
					<div class="title3">Q：为什么我的饰品可以在 Steam 市场交易但在充值时仍显示非可用饰品？</div>
					<div class="title4">A：网站出于对运营成本的控制，对市场上流通性较差的饰品是不予支持的，请您谅解。</div>
					<div class="title3">Q：充值没有到账？</div>
					<div class="title4">A：支付宝/微信支付系统可能会有延迟，超3分钟后仍未到账，请您及时联系网站客服进行处理，客服QQ ：1073161290。</div>

					<div class="name-title">4.每日盲盒活动相关问题</div>
					<div class="title3">Q：为什么提示我的 Steam 游戏时间小于10小时</div>
					<div class="title4">A：打开 Steam 个人资料，编辑个人资料，找到隐私设置，保证各项设置均保持公开，并且确保下面总游戏时间私密没有打✓,如设置完仍有问题，请联系客服刷新。
					</div>
					<div class="img"><img src="../assets/img/faq/faq3.png"></div>
					<div class="title3">Q：我邀请了人为什么没有获得盲盒?</div>
					<div class="title4">A：1）您邀请的玩家同样需要满足获得每日盲盒的条件（需要绑定 Steam 账号，账号设置为公开且游戏时长需要大于10小时）才会被系统认定为有效邀请。</div>
					<div class="title4">2）每个用户每天能获得盲盒数量上限为5个，如果你今天已经获得了5个盲盒，你也不会再获得更多盲盒。</div>

					<div class="name-title">5.饰品相关问题</div>
					<div class="title3">Q：为什么提示我饰品无法取回？</div>
					<div class="title4">A： 多次尝试取回饰品无法取回可能是账号交易权限被限制，请打开链接查找不可交易的原因后再尝试取回，<span @click="goUrl(url2)" style="cursor:pointer;text-decoration: underline;
					color: #17b4ed;">前往
							Steam 客服页面查询原因</span></div>
					<div class="img"> <img src="../assets/img/faq/faq4.png"></div>
					<div class="title4">PS：如问题仍然存在，可更换一个可用的 Steam 帐号绑定交易URL取回或联系客服解决。</div>
					<div class="title3">Q：Steam 未公开</div>
					<div class="title4">A：打开 Steam 个人信息，点击编辑个人资料，找到隐私设置，将库存选项调整为公开，并将下面选项框的 ✓ 取消。</div>
					<div class="img"><img src="../assets/img/faq/faq5.png"></div>
					<div class="title3">Q：取回了好久一直没有发送报价</div>
					<div class="title4">
						A：部分饰品采取人工发货途径，所以发货时间相比自动发货可能会有所延迟，我们会在全网为您搜索匹配的饰品，一般在24小时内即可发货，请您耐心等待，如遇发货失败可多尝试几次取回。</div>
					<div class="title4">PS：注意：报价发出后一小时未接受，系统将会自动取消交易。</div>

					<div class="name-title">6.其他问题</div>
					<div class="title3">Q：我如何使用邀请码？</div>
					<div class="title4">A：注册之后，你可以在个人中心--个人资料页面（第三选项）找到填写邀请码，邀请码会在你首次充值的时候给你提供 5% 的额外收益。
						你也可以在分销页面找到自己的分销链接和分销码，分享给你的好友。他们在游戏中的充值和押注行为也会为你带来收益。</div>
					<div class="title3">Q：饰品的价格依据是什么？</div>
					<div class="title4">A：我们根据 Steam 市场的价格给每个饰品估价，饰品估价在全平台都是一致的。盲盒奖励的饰品，您也可以选择直接兑换为余额。</div>
				</li>
			</ul>
		</div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <div class="cont" v-else>
      <div class="title-top"><span class="el-icon-arrow-left" @click="$router.back()" style="cursor:pointer"></span> Help center</div>
      <div class="collapse">
        <el-collapse v-model="activeNames">
          <el-collapse-item title="About Us" name="1">
  <div class="doubt1">
    <div>
      "Founded in December 2019, we are dedicated to developing digital content ecosystems for esports, providing users with safer entertainment channels. Using a unique trading model, we facilitate faster and more secure extraction of items from Steam inventories, which has been widely appreciated by many players."<br>
      "CSGOBOX.XYZ is a leading CSGO item trading platform in the region, offering comprehensive services. On the CSGOBOX.XYZ website, you can instantly obtain a wide range of CSGO items. Our Special Offer Mall features an extensive selection of products that can be quickly transferred back to your inventory, with stock levels unmatched by any other similar websites."<br>
      "Contact Information: 2748409013@qq.com"<br>
    </div>
  </div>
</el-collapse-item>
<el-collapse-item title="User Agreement" name="2">
  <div class="doubt2">
    <div class="doubt-warp">
      <div class="title">User Agreement</div>
      <div class="title1">
        "By registering an account on our website or using our services in any other way, you agree to the following terms and conditions ("Terms"). If you do not agree with these terms, do not register an account or use our services."
      </div>
      <div class="title1">
        "Please note that your use of the service is also subject to our Privacy Policy. The Company reserves the right to update the Terms and Privacy Policy at any time without prior notice."
      </div>

      <div class="con">
        <h3>1. "Definitions"</h3><br>
        "Account: An account created by a user on the platform to access the Services." <br>
        "Platform: The platform(s) released by the Company and/or its affiliates, which allows users to exchange tokens with each other. This platform does not permit the exchange of tokens for fiat currency." <br>
        "Services: Any services provided through the Website, affiliated websites, applications, and the Platform." <br>
        "Website: csgobox.xyz, located at: https://csgobox.xyz/" <br>
        "User: Any user of the Services (all references to 'you' or 'your' herein refer to each User)." <br>
        <h3>2. "Eligibility, Restricted Areas, and Permitted Use"</h3><br>
        "Eligibility. By accessing the Services, you warrant that:" <br>
        (1) "You are at least 18 years old, or at least the minimum age required by applicable law, so that we can legally provide the Services to you without parental consent;" <br>
        (2) "You have joined these Terms (and are legally capable of doing so);" <br>
        (4) "The laws of your country do not prohibit access to and use of the Services;" <br>
        (5) "You do not have a record of being suspended from trading or removed from a trading venue due to illegal activities;" <br>
        (6) "You own all tokens you use on the platform (or you have the authority to conduct authorized transactions with them);" <br>
        (7) "You have all necessary consents and authorizations to execute all transactions initiated from your account;" <br>
        (8) "You provide true, accurate, and complete registration information;" <br>
        (9) "You have reviewed the risk disclosure statement contained in these Terms and agree to bear all the risks described therein." <br>
        "Permitted Use." 
        "You agree to comply with all applicable laws and regulations when accessing the Services. You agree not to use the Services for any illegal activities, including but not limited to illegal gambling, money laundering, fraud, extortion, blackmail, data exchange, financing terrorism, or other violent activities. You also agree not to hack, decompile, reverse engineer, disassemble, attempt to obtain source code, decrypt, or otherwise compromise the integrity and security of the Platform, Site, or Applications." <br>
        <h3>3. "Accounts"</h3><br>
        "You must create an account to access and use the Services, and you must provide a valid email address to create an account. You must provide complete, accurate, and up-to-date information when creating an account and immediately update this information if any changes occur. You may request the Company to delete or remove any information you provide; however, you acknowledge and agree that the Company may refuse your request or restore any previously deleted or removed information. You are responsible for all activities under your account and bear the risk of unauthorized access. You agree to maintain the security and confidentiality of your login credentials and refuse unauthorized access to your account (whether through the Site, Application, or any other means). If you discover or suspect any unauthorized access or use of your account, you must immediately notify the Company." <br>
        "You agree that the Company has the right to limit the number of accounts any user or associated entity can create." <br>
        "You agree that the Company reserves the right to terminate your account at any time for any reason, at its sole discretion." <br>
        <h3>4. "Risk Disclosure Statement"</h3><br>
        (A) "Technology" <br>
        "Complexity." 
        "Tokens are generally described using highly technical language; a comprehensive understanding of applied cryptography and computer science is required to recognize the inherent risks. By using this Service, you represent and warrant that you have sufficient knowledge, market maturity, experience, and/or professional advice to prudently assess the value and risks of all transactions you make under this Service. You agree to assume full responsibility for such assessments." <br>
        "Cyber Attacks. If the Platform, Application, Site, or any other component of the Service is subjected to a cyber attack, your account and the tokens within it may be adversely affected. The Company and its affiliates do not guarantee that they can foresee, prevent, mitigate, or take corrective actions in the event of such attacks." <br>
        "Suspension/Discontinuation/Withdrawal. If a specific token traded on the Platform undergoes a fork or network attack, you agree that the Company may temporarily suspend services related to that token (with or without prior notice to you), and the Company may not support (or cease supporting) forked tokens. If feasible, the Company will provide you with an opportunity to recover such tokens. Resolutions regarding deposits, withdrawals, and user balances for attacked tokens will be determined by the Company on a case-by-case basis. The Company makes no representations or warranties regarding the security of the Platform and is not responsible for any loss of value or stolen property, regardless of whether the Company was negligent in providing adequate security." <br>
        (B) "Transaction Failures; Online Deposit Terms" <br>
        "Transactions on the Platform may fail for various reasons, including but not limited to price changes. The Company makes no representations or warranties regarding the normal execution of any transaction. The Company hereby disclaims all liability for any losses or damages arising from or related to any transaction failures. You acknowledge and agree that the Company is not responsible for notifying you of transaction failures." <br>
        "The Company may refuse to execute transactions, impose limits or restrictions on transaction amounts at any time without prior notice, and at its sole discretion. Specifically, if the Company suspects possible money laundering, terrorism financing, fraud, or any other illegal activity, or if the Company suspects that a transaction violates the relevant service terms [which terms?], such as transactions involving opening an account and subsequently closing it without conducting any actual transactions, the Company reserves the right to refuse processing, cancel, or revoke any transaction and disable the user's account. While the Company may, at its discretion, revoke transactions, users may not alter, withdraw, or cancel their transaction authorization, except for partially filled orders. The Company may correct, reverse, or cancel any transactions affected by errors in processing transaction instructions or for other reasons. If an error occurs, your remedies will be limited. We cannot guarantee that such cancellations or refunds are always possible. The Company's platform operates by matching each user's order with other users' orders. Orders can be partially filled or multiple orders can be filled based on trading activity at the time of placing the order. You confirm that the Company provides encrypted token storage for each account to facilitate trading." <br>
        (C) Suspension <br>
        The Company has the right to terminate the availability of previously provided tokens at any time. In such cases, the Company will provide you with an opportunity to recover these tokens. <br>
        <h3>5. "License Restrictions"</h3><br>
        The Company grants you a limited, non-exclusive, and non-transferable license to access and use the Services (whether through the Application or the Website) solely for the intended functionality of the Services and for any other approved uses explicitly permitted by the Company. Any other use of the Services is strictly prohibited. <br>
        When using the Application, you agree not to: <br>
        (A) Decompile, reverse engineer, disassemble, attempt to export the source code of the Application, or decrypt the Application; <br>
        (B) Modify, adapt, improve, enhance, translate, or create derivative works from the Application; <br>
        (C) Violate any applicable laws, rules, or regulations related to your access or use of the Application; <br>
        (E) Use the Application for any purpose other than what it is designed or intended for; <br>
        (F) Provide the Application over a network or otherwise allow multiple mobile devices or users to access or use the Application simultaneously; <br>
        (G) Send automated queries to any website or send any unsolicited commercial emails using the Application; <br>
        (H) Use the Company’s or its affiliates’ proprietary information or intellectual property (including but not limited to trademarks) without authorization. <br>
        <h3>6. "Code of Conduct for Users"</h3><br>
        When using or accessing the Services, you agree not to: <br>
        (A) Obtain or attempt to obtain unauthorized access to the Website, other users' accounts, or any other component of the Services through password mining or any other means; <br>
        (B) Use or attempt to use web crawlers on the Website or any part of the Services; <br>
        (C) Place an excessive burden on our infrastructure; <br>
        (D) Upload any material containing computer viruses, Trojan horses, or worms to the Website. <br>
        <h3>7. "Privacy Policy"</h3><br>
        Your privacy is important to us. However, we may be required to share your information according to applicable laws, rules, or regulations. For more details, please refer to our Privacy Policy. <br>
        <h3>8. "Anti-Money Laundering and Know Your Customer (KYC) Policy"</h3><br>
        The Company implements bank-level ("Know Your Customer") processes to comply with Anti-Money Laundering (AML) laws. The Company cross-references user data against government watch lists. If a user or their transactions are flagged as suspicious, the Company will require additional identification and may suspend any transactions, deposits, or withdrawals until satisfactory identification is received. <br>
        The Company uses internal policies for user verification levels. The Company has the right to determine appropriate verification levels and standards for any user and may lower verification levels without prior notice. The Company may implement measures to restrict verification levels and standards based on nationality, country of residence, or any other factors. <br>
        You agree that if the required verification level cannot be met, you may not be able to deposit or withdraw tokens. You agree to bear full responsibility and will not hold the Company or its affiliates liable for any such losses. <br>
        You hereby authorize the Company to directly (or through third parties) conduct any necessary inquiries to verify your identity and/or prevent fraudulent activities, including querying publicly available reports containing identity information (such as your name, address, past addresses, or date of birth), querying account information related to bank accounts associated with you (such as name or account balance), and taking actions we deem reasonable and necessary based on the results of such inquiries and reports. You also authorize any and all third parties who may receive such inquiries or requests to respond fully to them. <br>
        <h3>9. "Non-Transferability"</h3><br>
        Although accounts and services provided to users are non-transferable, the Company has the right to assign, transfer, or sell ownership and interests in accounts and services to anyone. These Terms remain effective for the Company or its lenders' successors and assigns (if any). <br>
        <h3>10. "Permanent Withdrawal of Services"</h3><br>
        The Company has the right to suspend or terminate your access to the Services. If the Company suspects, based on applicable laws, rules, or regulations, that you or someone else is using your account for illegal activities, the Company may deactivate or cancel your account. In such cases, unless prohibited by applicable law, you may transfer tokens associated with your account within 60 days after the account is deactivated or canceled. If any transactions are pending when your account is canceled or suspended, they may be canceled or refunded as needed. <br>
        Token return process: The Company cancels your account — You authorize the Company to cancel or suspend pending transactions — The Company notifies you via the Service or Application — Tokens associated with the transaction will be returned to the wallet address the Company retains for you. <br>
        If the Company cannot reasonably return your tokens to the wallet address retained for you by CoinFit, the Company may report and remit the tokens to the applicable government agency under Hong Kong's unclaimed property laws. <br>
        <h3>11. "Disclaimer and Limitation of Liability"</h3><br>
        You understand and agree that, under no circumstances, shall we be liable for any of the following matters: <br>
        (1) Loss of income; <br>
        (2) Loss of trading profits or contractual losses; <br>
        (3) Business interruption; <br>
        (4) Loss of anticipated savings; <br>
        (5) Loss of information; <br>
        (6) Loss of opportunities, goodwill, or reputation; <br>
        (7) Damage or loss of data; <br>
        (8) Costs of purchasing replacement products or services; <br>
        (9) Extinction or exit of digital assets from the market; <br>
        (10) Any indirect, special, or consequential losses or damages arising from or related to any cause of action (including negligence), breach of contract, or any other cause, whether or not such losses or damages were foreseeable; whether or not we were informed of the possibility of such losses or damages. <br>
        Clauses (1) to (10) are independent of each other. <br>
        You understand and agree that we are not liable for any damages resulting from the following situations: <br>
        (1) We have reasonable grounds to believe that a specific transaction may involve significant illegal or breach of contract behavior. <br>
        (2) We have reasonable grounds to believe that your actions on our platform are illegal or inappropriate. <br>
        (3) Any costs or losses incurred from purchasing or obtaining any data, information, or conducting transactions through our services, or from alternative actions. <br>
        (4) Misunderstandings about our services. <br>
        (5) Any loss, damage, or destruction of your information caused by reasons unrelated to us. <br>
        (6) Any other losses related to our services caused by reasons unrelated to us. <br>
        (7) We cannot guarantee that all information, programs, texts, etc., included on our platform are completely secure and free from interference or destruction by any viruses, Trojans, or other malicious programs. Therefore, logging into, using our services, or downloading and using any programs, information, or data from our platform is at your own risk and you bear any potential losses. <br>
        (8) We make no representations or warranties regarding any information, products, or businesses on any third-party websites we link to. Using any services, information, or products provided by third-party websites is at your own discretion and you bear all responsibilities. <br>
        (9) We make no express or implied warranties regarding your use of our services, including but not limited to the applicability, absence of errors or omissions, continuity, accuracy, reliability, or suitability for a particular purpose of the services. We also make no representations or warranties regarding the effectiveness, accuracy, correctness, reliability, quality, stability, completeness, or timeliness of the technology and information involved in the services we provide. Whether to log in or use our services is your personal decision, and you bear the risk and any potential losses. We make no express or implied warranties regarding the market, value, or price of digital assets. You understand and acknowledge that the digital asset market is unstable, and prices and values can fluctuate or collapse dramatically. Trading digital assets is your personal choice and decision, and you bear the risk and any potential losses. <br>
        (10) The warranties and commitments specified in this agreement are the only warranties and statements made by us regarding this agreement and the services we provide, and they replace any other warranties and commitments arising from any other means, whether written or oral, express or implied. All these warranties and statements represent only our own commitments and guarantees and do not guarantee that any third party will comply with the warranties and commitments in this agreement. <br>
        (11) We do not waive any rights we enjoy under applicable law to limit, exempt, or offset our liability for damages not mentioned in this agreement. <br>
        <h3>12. "Governing Law and Dispute Resolution"</h3><br>
        This User Agreement is governed by and construed in accordance with the laws of Hong Kong and should be interpreted as a Hong Kong contract. Any claims or litigation arising from or related to this User Agreement (including tort claims) shall be governed by and construed in accordance with Hong Kong law. <br>
        To expedite and control the cost of disputes, you agree that any claims arising from your use of the Services or this Agreement, or related to your use of the Services or this Agreement, including the formation, validity, enforceability, scope, or applicability of this Agreement (hereinafter referred to as "Claims") will be resolved as follows: Except for claims for injunctive or equitable relief or intellectual property claims (which may be brought in any competent court without posting a bond), any disputes arising from your use of the Services will be finally resolved through confidential, binding arbitration administered by the Hong Kong International Arbitration Centre (hereinafter referred to as the "Arbitration Centre"). This means that, except for intellectual property lawsuits, such as copyright or trademark infringement lawsuits, or claims seeking non-monetary relief, all Claims will be subject to binding arbitration. Arbitration will be conducted in English in Hong Kong, and the arbitration award may be enforced by any court. <br>
        To initiate the arbitration process, you must send a letter requesting arbitration by mail and describe your claim to us. <br>
        <h3>13. "No Class Actions"</h3><br>
        You and the Company can only bring claims against each other in your or its individual capacity and not as a plaintiff or class member in any purported class, representative, consolidated, or multi-claimant lawsuit. <br>
        Both you and the Company waive the right to a jury trial. <br>
        <h3>15. Integration</h3><br>
This Agreement, together with any policies or operating rules published by the Company, constitutes the entire agreement and understanding between you and the Company and supersedes any prior or contemporaneous agreements, communications, and proposals (whether oral or written) between you and the Company (including, but not limited to, any previous versions of this Agreement).<br>

<h3>16. Force Majeure</h3><br>
In addition to the applicable disclaimers above, the Company shall be excused from performance under this Agreement if such performance is delayed or prevented due to causes beyond the reasonable control of the Company, including but not limited to acts of God, government actions, war or other hostilities, civil unrest, fire, flood, snowstorm, earthquake, explosion, embargo, terrorism, power failure, equipment failure, labor disputes or controversies, actions of any third-party data provider or other third-party information provider, interruption of third-party software or communication methods.<br>

<h3>17. Amendments</h3><br>
The Company may modify, delete, or otherwise change any terms of this Terms of Use and Privacy Policy at any time; provided that, the Company will notify you at least seven (7) days in advance of any changes via email on file with the Company.<br>

<h3>18. Suspension and Termination of Service</h3><br>
The Company may suspend or terminate the service for any reason, with or without notice. You acknowledge and agree that if market conditions are unstable or unreasonable, the Company may, with or without notice, terminate the service by initiating trading restrictions or other appropriate measures.<br>
            </div>
            </div></div>
          </el-collapse-item>
          <el-collapse-item title="Privacy Policy" name="3">
  <div class="doubt3">
    <div class="doubt-warp">
      <div class="title">Privacy Policy</div>
      <div class="con">
        This Privacy Policy applies to all services provided by csgobox.xyz. When you use any of our individual services, you agree to be bound by this Privacy Policy and any specific privacy information policies (hereinafter referred to as "specific terms") issued in that individual service. In such cases, the specific terms and this policy will apply to you simultaneously.<br>
        Please note that we periodically review our policies, so the relevant measures may change. We encourage you to visit this page regularly to ensure you are always informed about the latest version of our Privacy Policy. If you have any questions about this Privacy Policy or related matters after reading it, please contact csgobox.xyz customer service.<br>
        By using or continuing to use csgobox.xyz services, you agree that we collect, use, store, and share your information in accordance with this Privacy Policy.<br>

        <h3>1. Information We May Collect</h3><br>
        (a) Non-Personally Identifiable Information:<br>
        When you use csgobox.xyz services, we may collect and aggregate information such as user sources, visit sequences, etc., for example, recording the source path, browser software, etc., of each user who uses csgobox.xyz services.<br>
        (b) Personally Identifiable Information:<br>
        When you use csgobox.xyz services, we may collect and aggregate or request you to provide personally identifiable information, such as: birthday, place of origin, gender, interests, personal phone number (including system account, IP address, email address, etc.); personal financial information (transaction records, balance, coupons, redemption codes, etc.); communication information; personal internet usage records and logs (including website browsing records, software usage records, click records, operation logs, etc.); device information (including device model, MAC address, operating system type, device settings); unique device identifiers (such as IMEI, android ID, IDFA, OPENUDID, GUID, SIM card IMSI information, etc., which describe the basic information of personal commonly used devices); location information (including travel information, precise location information, accommodation information, latitude and longitude, etc.).<br>
        We collect your information primarily to make it easier and more satisfying for you and other users to use csgobox.xyz services. Our goal is to provide a safe, exciting, interesting, and educational online experience for all internet users. This information helps us achieve that goal.<br>

        <h3>2. How We Collect and Use Information</h3><br>
        (a) We collect and obtain your information through the following means:<br>
        1. Information You Provide. For example:<br>
        (1) Information you provide when registering for a csgobox.xyz account or using csgobox.xyz services;<br>
        (2) Shared information you provide to others through csgobox.xyz services, as well as information stored when using csgobox.xyz services.<br>
        2. Information We Collect. When you use csgobox.xyz services, we collect, aggregate, and record information such as log information, location information, and device information.<br>
        (b) COOKIES, Log Files, and WEB BEACON<br>
        We or our third-party partners may collect and use your information through COOKIES and WEB BEACON and store it as log information. COOKIES provide users with a simple, personalized web experience. A COOKIE is a small piece of data sent from a web server to your browser and stored on your computer's hard drive. We use COOKIES to enhance user convenience. For example, to make logging into the csgobox.xyz virtual community faster, you can choose to save your username in a COOKIE. The next time you log in to csgobox.xyz services, it will be more convenient. COOKIES help us determine the pages and content you connect to, the time you spend on csgobox.xyz services, and the csgobox.xyz services you select. COOKIES allow us to serve you better and faster, and provide a more personalized experience on csgobox.xyz services. However, you should be able to control whether and how COOKIES are accepted by your browser. Please refer to the documentation accompanying your browser for more information.<br>
        We use our own COOKIES and WEB BEACON for the following purposes:<br>
        (1) Remember your identity. For example, COOKIES and WEB BEACON help us recognize you as a registered user and remember preferences or other information you have provided;<br>
        (2) Analyze your use of our services. We can use COOKIES and WEB BEACON to understand what activities you engage in on csgobox.xyz services and which services or features are most popular;<br>
        (3) Ad optimization. COOKIES and WEB BEACON help us deliver ads that are more relevant to you rather than general advertising.<br>
        While using COOKIES and WEB BEACON for these purposes, we may aggregate non-personally identifiable information collected through COOKIES and WEB BEACON and provide it to advertisers and other partners for analysis of how users use csgobox.xyz services and for advertising purposes.<br>
        Third-party COOKIES and WEB BEACON on csgobox.xyz services may collect non-personally identifiable information about you to analyze how you use the services, send you ads that may interest you, or evaluate the effectiveness of ad campaigns. These third-party COOKIES and WEB BEACON are not subject to this Privacy Policy but are governed by their own privacy statements, and we are not responsible for third-party COOKIES or WEB BEACON.<br>
        You can refuse or manage COOKIES or WEB BEACON through your browser or user choice mechanisms. However, please note that if you disable COOKIES or WEB BEACON, we may not be able to provide you with the best service experience, and some services may not function properly. You will still receive ads, but they will be less relevant to you.<br>
        (c) We collect and use your information for the following purposes:<br>
        1. To assist you in completing registration<br>
        To facilitate our provision of services, you need to provide basic registration information, such as mobile phone number, email address, and create a username and password. In some individual services, if you only need to use basic services like browsing and searching, you do not need to register as a csgobox.xyz user or provide the above information.<br>
        2. To provide you with products or services<br>
        The information we collect and use is necessary for providing csgobox.xyz services. Without certain information, we cannot provide the core content of csgobox.xyz services, for example:<br>
        (1) In some service projects, to facilitate the delivery of goods or services, you need to provide personal identity information, name, shipping address, postal code, recipient, contact number, payment status, etc. If you refuse to provide this information, we will not be able to complete the delivery. If you order goods or services for others through csgobox.xyz services, you need to provide the aforementioned information of the actual orderer. Before providing this information to us, you must ensure that you have obtained their authorization.<br>
        (2) To display your order information, we collect order information generated during your use of csgobox.xyz services for your viewing and management;<br>
        (3) When you contact us, we may save your communication/call records and content or contact information to assist you or resolve issues, or document problem-solving methods and results;<br>
        (4) To confirm transaction status and provide after-sales and dispute resolution services, we may collect transaction, payment, and logistics information related to the progress of transactions from the parties you choose based on the transaction, payment institutions, logistics companies, etc., or share your transaction information with these service providers.<br>
        3. To provide security guarantees<br>
        To ensure your true identity and provide better security, you can provide us with personal sensitive information such as identity proof, facial recognition, etc., to complete real-name verification.<br>
        In addition to identity verification, we may use or integrate the information we collect from you, as well as information obtained from our partners with your authorization or in accordance with the law, to comprehensively assess account and transaction risks, perform identity verification, detect and prevent security incidents, and take necessary record-keeping, auditing, analysis, and handling measures.<br>

        <h3>3. Sharing, Transfer, and Disclosure of Information</h3><br>
        (a) Sharing<br>
        Except in the following circumstances, we will not share your information with any third party outside csgobox.xyz without your consent:<br>
        1. To provide you with our services. We may share your information with partners and other third parties to achieve core functions or provide services you need, such as sharing corresponding order information with logistics service providers;<br>
        2. To maintain and improve our services. We may share your information with partners and other third parties to help us provide more targeted and comprehensive services, such as communication service providers who send emails or push notifications on our behalf;<br>
        3. To achieve the purposes stated in Section 2 "How We Collect and Use Information" of this Privacy Policy;<br>
        4. To fulfill our obligations and exercise our rights under this Privacy Policy or other agreements we have with you;<br>
        5. To share with partners who commission us for promotions, but we only provide information about the coverage and effectiveness of the promotion, not personally identifiable information such as names, phone numbers, or email addresses. For example, we can inform the partner how many people viewed their promotional information or purchased their products after seeing it, or provide aggregated statistical information that does not identify individuals to help them understand their audience or customers.<br>
        6. Within the scope permitted by laws and regulations, to comply with legal requirements, maintain the interests, property, or safety of us, our affiliates, partners, you, or other csgobox.xyz users or the public, such as preventing fraud and reducing credit risks. We may exchange information with other companies and organizations. However, this does not include selling, renting, sharing, or otherwise disclosing information for profit in violation of the commitments made in this Privacy Policy.<br>
        (b) Transfer<br>
        1. As our business continues to develop, we may undergo mergers, acquisitions, asset transfers, or similar transactions, and your information may be transferred as part of such transactions. We will require the new entity holding your information to continue to be bound by this Privacy Policy, or we will require them to seek your explicit consent again.<br>
        2. We will transfer your information to other parties with your explicit consent.<br>
        (c) Disclosure<br>
        We will only disclose your information under the following circumstances, and we will take industry-standard security measures:<br>
        1. Disclose the information you specify in the manner you explicitly agree to;<br>
        2. Disclose your information when required by law, regulations, or mandatory administrative or judicial requests. When we receive such disclosure requests, we will require the requesting party to provide appropriate legal documents, such as subpoenas or investigation letters. We carefully review all requests to ensure they have a legitimate basis and are limited to data that law enforcement has a lawful right to access.<br>

        <h3>4. Retention, Storage, and Protection of Information</h3><br>
        We will adopt the following measures to protect your information:<br>
        (a) Data Security Technology Measures<br>
        We implement industry-standard security protection measures, including establishing reasonable institutional norms, security technologies to prevent unauthorized access, use, modification, damage, or loss of your information. For example, we use encryption technology (such as SSL) to protect your information and store it in encrypted form, and use isolation technology to isolate data. During information usage, such as information display and association calculations, we use multiple data anonymization techniques to enhance the security of information usage. We also implement strict data access permission controls and multi-factor authentication to prevent unauthorized use of data.<br>
        (b) Other Security Measures We Take to Protect Information<br>
        We establish data classification and grading systems, data security management norms, and data security development norms to manage and standardize the storage and use of information.<br>
        We control data through confidentiality agreements with information handlers, monitoring, and auditing mechanisms.<br>
        We also hold training sessions on security and privacy protection to enhance employees' awareness of the importance of protecting information.<br>
        (c) We only allow csgobox.xyz employees and partners who need to know this information to access it and have set up strict access permission controls and monitoring mechanisms. We require all personnel who may come into contact with your information to fulfill confidentiality obligations. If they fail to do so, they may face legal liability or termination of cooperation with csgobox.xyz.<br>
        (d) We will take all reasonable and feasible measures to ensure that we do not collect irrelevant information.<br>
        (e) The Internet is not an absolutely secure environment, and communication tools such as email, instant messaging, social software, or other service software may not be fully encrypted. We recommend using complex passwords and protecting your information.<br>
        (f) The Internet is not 100% secure, and we will strive to ensure or guarantee the security of any information you send to us. If our physical, technical, or management protective facilities are compromised, leading to unauthorized access, disclosure, alteration, or destruction of information, resulting in damage to your legal rights, we will bear corresponding legal responsibility.<br>
        (g) Incident Handling<br>
        When communicating or purchasing goods and services through csgobox.xyz services, you may need to disclose your information to the transaction party or potential transaction party. Please protect your information appropriately and only disclose it when necessary.<br>
        To address potential risks of information leakage, damage, and loss, we have established multiple policies, clearly defining the classification and grading standards of security incidents and vulnerabilities and the handling procedures. We also have a dedicated emergency response team for security incidents, initiating emergency plans according to the security incident handling guidelines. We take measures to stop losses, analyze, locate, and formulate remedial measures, and work with relevant departments to trace and combat the source.<br>
        In the event of a security incident, we will notify you promptly in accordance with legal requirements: the basic situation and possible impact of the security incident, the measures we have taken or will take, suggestions for you to prevent and reduce risks, and remedial measures for you. We will also inform you of the incident-related details via email, letter, phone, push notifications, etc. If it is difficult to notify each information subject individually, we will take reasonable and effective ways to issue announcements. At the same time, we will report the handling of the security incident to the regulatory authorities as required.<br>
        Please understand that due to technological limitations and the limitations of risk prevention, even with enhanced security measures, we cannot guarantee 100% security of information. You should be aware that the system and communication networks you use to access csgobox.xyz services may encounter problems beyond our control.<br>

        <h3>5. How to Contact Us</h3><br>
        If you have complaints or reports regarding network information security, or if you have any questions, opinions, or suggestions about this Privacy Policy, your information, or any issues related to this statement or csgobox.xyz's privacy practices, please contact the coordinator at csgobox.xyz.
      </div><br>
    </div>
  </div>
</el-collapse-item>
<el-collapse-item title="Terms and Conditions" name="4">
  <div class="doubt4">
    <div class="doubt-warp">
      <div class="title">Terms and Conditions</div>
      <div class="title1">1. Refund Terms and Exemption Clauses</div>
      <div class="con">
        <span>1. Users understand and acknowledge that during the use of the services under this agreement, there may be unforeseen risks such as force majeure that can lead to issues with deposits not being credited. Force majeure refers to objective events that cannot be foreseen, avoided, or overcome and that affect one or both parties, including server anomalies, urgent bugs, delays in payment channels, etc. In such cases, csgobox.xyz will make every effort to address the issue promptly and ensure the deposit is credited. However, this cannot be used as a reason for refunds.</span><br>

        <span>2. The services under this agreement, like most internet services, are subject to various security issues due to factors such as user behavior, network service quality, and social environment. These issues include harassment in real life caused by others using your information, threats to your computer's information and data security from viruses like "Trojan horses" in software you download or websites you visit, and disruptions to the normal use of the services. Users should enhance their awareness of information security and protect their personal data, especially by strengthening password protection to avoid losses and harassment.</span><br>

        <span>3. Users understand and acknowledge that the services under this agreement may be interrupted or fail to meet user requirements due to force majeure, computer viruses, hacker attacks, system instability, user location, user shutdowns, and other technical, internet, or communication line reasons. Any resulting losses to users or third parties will be exempted from liability by csgobox.xyz within the limits of the law, and unreasonable complaints will be rejected.</span><br>

        <span>4. Users understand and acknowledge that the services under this agreement may expose users to misleading, deceptive, threatening, defamatory, offensive, or illegal information from others, including anonymous or impersonated information that infringes on the rights of others. Any resulting losses to users or third parties will not be the responsibility of csgobox.xyz.</span><br>

        <span>5. Users understand and acknowledge that csgobox.xyz needs to perform regular or irregular maintenance on its systems or related equipment. If such maintenance causes service interruptions within a reasonable time, the platform will not be held liable, but csgobox.xyz will notify users in advance.</span>
      </div>

      <div class="title1" style="margin-top: 42px;">2. Retrieval Terms</div>
      <div class="con">
        <span>1. If the item a user wishes to retrieve is out of stock, they can apply for restocking through customer service. If the supplier is temporarily out of stock, please be patient.</span><br>

        <span>2. In case of retrieval failures or delays in crediting, csgobox.xyz has the obligation to resolve the issue promptly and protect user rights. However, this does not serve as a reason for refunds or malicious complaints.</span><br>

        <span>3. If a user makes a mistaken withdrawal or if their account is compromised leading to unauthorized withdrawals, the platform will do its best to assist in resolving the issue. However, this does not serve as a reason for refunds or malicious complaints.</span>
      </div>
    </div>
  </div>
</el-collapse-item>
<el-collapse-item title="Frequently Asked Questions" name="5">
  <div class="doubt-warp5">
    <ul>
      <li>
        <div class="name">Contact Us</div>
        <p class="p">Contact us, and we will handle your feedback as soon as possible.</p>
        <p class="p">Customer Service Email: {{email}}</p>
        <p class="p">QQ: {{qq}}</p>
      </li>
      <li>
        <div class="name">General Questions</div>

        <div class="name-title">1. Unable to Register/Login via Steam</div>
        <p class="title-p">Due to domestic restrictions, users may encounter issues such as inability to connect or access denied when using Steam for registration/login. Users can resolve this by installing a gaming accelerator to speed up the Steam community. If problems persist, try changing your browser.</p>

        <div class="name-title">2. Obtaining Steam Trade Links</div>
        <div class="title1">2.1 PC Users</div>
        <div class="title2">(1). <span @click="goUrl(url1)" style="cursor:pointer;text-decoration: underline; color: #17b4ed;">Get Steam Trade Address</span></div>
        <div class="title2">(2). Obtain Steam trade links from third-party websites.</div>
        <div class="img"><img src="../assets/img/faq/faq1.png"></div>
        <div class="title1">2.2 Mobile Users</div>
        <div class="title2">(1). Download the Steam App, log in, and follow the process "You & Friends—Inventory—Trade Offers—Right Menu Button—Who Can Send Me Trade Offers" to find the trade link at the bottom.</div>
        <div class="img"><img src="../assets/img/faq/faq2.png"></div>

        <div class="name-title">3. Deposit-Related Issues</div>
        <div class="title3">Q: What is the deposit handling fee?</div>
        <div class="title4">A: The platform charges a 15% handling fee on the total amount of item deposits (still higher than market prices). The fee covers costs related to the 7-day trading restriction on items, account lock risks, and fees paid to third-party platforms when selling items.</div>
        <div class="title3">Q: Why does it show that my item is not available for deposit?</div>
        <div class="title4">A: Items with trading cooldowns or restrictions cannot be deposited.</div>
        <div class="title3">Q: Why can I trade my item on the Steam Market but it still shows as not available for deposit?</div>
        <div class="title4">A: For operational cost control, the website does not support items with poor liquidity in the market. Please understand.</div>
        <div class="title3">Q: My deposit has not been credited?</div>
        <div class="title4">A: There might be delays in the Alipay/WeChat Pay system. If it hasn't been credited after 3 minutes, please contact our customer service immediately. Customer Service QQ: 1073161290.</div>

        <div class="name-title">4. Daily Mystery Box Event Questions</div>
        <div class="title3">Q: Why does it say my Steam playtime is less than 10 hours?</div>
        <div class="title4">A: Open your Steam profile, edit your profile, and ensure all privacy settings are set to public. Make sure the "Total Playtime Private" option is not checked. If issues persist, contact customer service to refresh your data.</div>
        <div class="img"><img src="../assets/img/faq/faq3.png"></div>
        <div class="title3">Q: Why didn't I get a mystery box after inviting someone?</div>
        <div class="title4">A: 1) The player you invited must also meet the conditions for daily mystery boxes (bind Steam account, set profile to public, and have more than 10 hours of playtime) to be considered a valid invitation.</div>
        <div class="title4">2) Each user can receive up to 5 mystery boxes per day. If you've already received 5 boxes today, you won't get any more.</div>

        <div class="name-title">5. Item-Related Questions</div>
        <div class="title3">Q: Why does it say my item cannot be retrieved?</div>
        <div class="title4">A: Multiple failed attempts to retrieve an item may indicate that your account's trading permissions are restricted. Check the reason for non-trading and try again. <span @click="goUrl(url2)" style="cursor:pointer;text-decoration: underline; color: #17b4ed;">Visit the Steam Support Page to Find Out Why</span></div>
        <div class="img"><img src="../assets/img/faq/faq4.png"></div>
        <div class="title4">PS: If the issue persists, try binding a different Steam account with a valid trade URL or contact customer service for assistance.</div>
        <div class="title3">Q: My Steam Profile Is Not Public</div>
        <div class="title4">A: Open your Steam profile, click "Edit Profile," find the privacy settings, and set the inventory option to public. Uncheck the box below.</div>
        <div class="img"><img src="../assets/img/faq/faq5.png"></div>
        <div class="title3">Q: It's been a long time since I requested retrieval, but no offer has been sent</div>
        <div class="title4">
          A: Some items are shipped manually, which may cause delays compared to automatic shipping. We will search the web to match and ship your items, usually within 24 hours. Please be patient. If shipping fails, try retrieving the item again.
        </div>
        <div class="title4">PS: Note: If the offer is not accepted within one hour, the system will automatically cancel the trade.</div>

        <div class="name-title">6. Other Questions</div>
        <div class="title3">Q: How do I use an invite code?</div>
        <div class="title4">A: After registration, you can find the invite code field in your personal center—profile page (third option). The invite code provides an additional 5% bonus on your first deposit. You can also find your distribution link and code on the distribution page and share them with your friends. Their deposits and betting activities in the game will also generate income for you.</div>
        <div class="title3">Q: What is the basis for item pricing?</div>
        <div class="title4">A: We estimate the price of each item based on the Steam Market. Item valuations are consistent across the platform. You can also choose to exchange mystery box rewards directly for balance.</div>
      </li>
    </ul>
  </div>
</el-collapse-item>
        </el-collapse>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      qq: "3692073479",
      email: "2748409013@qq.com",
      url1: "https://steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url",
      url2: "https://help.steampowered.com/zh-cn/wizard/Login?redir=%2Fzh-cn%2Fwizard%2FHelpWhyCantITrade&title=%E7%99%BB%E5%BD%95",
      lang:
        localStorage.getItem("lang") ||
        this.$store.state.currentLanguage ||
        "en",
    };
  },
  methods: {
    //获得steam交易链接
    goUrl(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang='less' scoped>
.container {
  color: white;
  width: 100%;
  min-height: calc(100vh - 175px);
  display: flex;
  justify-content: center;
  background-color: #1d1d26;
  @media (max-width: 550px) {
    min-height: calc(100vh - 145px);
  }
  .cont {
    @media (max-width: 550px) {
      width: 95%;
    }
    width: 75%;
    height: 100%;
    margin-top: 30px;
    // background-color: aqua;
    .title-top {
      padding-bottom: 15px;
      border-bottom: 1px solid rgb(83, 82, 82);
    }
    .collapse {
      margin-top: 5px;
    }
  }
}

.doubt1 {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  // background-color: #1a1c24;
  // padding-top: 42px;

  .doubt-warp {
    // width: 1200px;
    margin: 0 auto;
    // padding-top: 88px;
    // background-color: #2b2c37;
    padding: 32px;
  }

  .title {
    padding: 20px 0 64px 0;
    font-size: 30px;
    color: #c3c3e2;
  }

  .con {
    font-size: 18px;
    color: #848492;

    span {
      display: inline-block;
      margin-top: 10px;
    }
  }
}

.doubt2 {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  // background-color: #1a1c24;
  // padding-top: 42px;

  .doubt-warp {
    // width: 1200px;
    margin: 0 auto;
  }

  .title {
    // padding: 20px 0 64px 0;
    font-size: 30px;
    color: #c3c3e2;
  }

  .con {
    margin-top: 46px;
    margin-bottom: 88px;
    font-size: 18px;
    color: #848492;

    h3 {
      color: #ddd;
      font-weight: normal;
      margin-top: 42px;
    }
  }

  .title1 {
    color: #c3c3e2;
    font-size: 20px;
    padding-bottom: 10px;
  }
}

.doubt3 {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  // background-color: #1a1c24;
  // padding-top: 12px;
  .doubt-warp {
    // width: 1200px;
    margin: 0 auto;
  }
  .title {
    padding: 20px 0 64px 0;
    font-size: 30px;
    color: #c3c3e2;
  }
  .con {
    font-size: 18px;
    color: #848492;
    padding-bottom: 88px;
    h3 {
      color: #ddd;
      font-weight: normal;
      margin-top: 42px;
    }
  }
}

.doubt4 {
  .doubt-warp {
    // width: 1200px;
    margin: 0 auto;
    // padding: 32px;
  }
  .title {
    // padding: 20px 0 64px 0;
    font-size: 30px;
    color: #c3c3e2;
  }
  .title1 {
    font-size: 20px;
    color: #c3c3e2;
    padding: 16px 0;
  }
  .con {
    font-size: 18px;
    color: #848492;
    span {
      margin-top: 10px;
      display: inline-block;
    }
  }
}

.doubt-warp5 {
  // width: 1200px;
  margin: 0 auto;
  // padding: 20px 0;
  margin-bottom: 50px;

  ul li {
    .name {
      color: #c3c3e2;
      font-size: 24px;
      font-weight: 600;
    }

    .name-title {
      font-size: 20px;
      // margin: 20px 0 0 20px;
      color: #c3c3e2;
    }

    .p {
      font-weight: 400;
      font-size: 16px;
      color: #848492;
      // padding: 8px 0;
    }

    .title-p {
      color: #848492;
      // margin: 20px 0 0 20px;
    }
  }

  .title1 {
    // padding: 20px 0 0 40px;
    font-size: 16px;
    color: #c3c3e2;
    font-weight: 600;
  }

  .title2 {
    // padding: 20px 0 0 60px;
    font-size: 16px;
    color: #848492;

    span {
      text-decoration: underline;
      color: #17b4ed;
    }

    span:hover {
      cursor: pointer;
    }
  }

  .title3 {
    // padding: 20px 0 0 60px;
    font-size: 14px;
    color: #c3c3e2;
    font-weight: 600;
  }

  .title4 {
    // padding: 20px 0 0 60px;
    font-size: 14px;
    color: #848492;
    font-weight: 600;

    span {
      text-decoration: underline;
      color: #17b4ed;
    }

    span:hover {
      cursor: pointer;
    }
  }

  .img {
    // padding: 20px 0 0 60px;
    img {
      @media (max-width: 550px) {
        width: 90vw !important;
        height: auto;
      }
    }
  }
}

::v-deep .el-collapse-item__header {
  background-color: #0e0d0e;
  border: none;
  margin-top: 5px;
  color: white;
  height: 40px;
  // margin-left: 5px;
  padding-left: 10px;
}
::v-deep .el-collapse-item__header .is-active {
  background-color: #0e0d0e;
  color: white;
}
::v-deep .el-collapse-item__wrap {
  background-color: #0e0d0e;
  border: none;
  color: white;
  padding: 0 10px;
  div {
    color: white;
    font-size: 16px;
  }
}
::v-deep .el-collapse-item__wrap .is-active {
  background-color: #0e0d0e;
  border: none;
  color: white;
}
::v-deep .el-collapse {
  border: none;
  color: white;
}
</style>